import { BACKEND_URL } from "../config";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RegisterFormType } from "../components/forms/Register";
import { LoginFormType } from "../components/forms/Login";
import { getSession } from "../../js/api/session";
import { Session } from "../context/SessionContext";
import { UserInfo } from "../components/dashboard/Profile";
import { PasswordChange } from "../components/dashboard/PasswordChange";
import { ResetFormType } from "../components/forms/Reset";

const registerUser = (url: string, data: RegisterFormType) => {
    return axios.request({
        url: url,
        data: data,
        withCredentials: true,
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        method: "POST",
    });
};

export const useRegister = () => {
    const url = `${BACKEND_URL}/api/register`;

    return useMutation({
        mutationKey: [url],
        mutationFn: async (data: RegisterFormType) => {
            return await registerUser(url, data);
        },
    });
};

const loginUser = (url: string, data: LoginFormType) => {
    return axios.request({
        url: url,
        data: data,
        withCredentials: true,
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        method: "POST",
    });
};

export const useLogin = () => {
    const url = `${BACKEND_URL}/api/login`;

    return useMutation({
        mutationKey: [url],
        mutationFn: async (data: LoginFormType) => {
            return await loginUser(url, data);
        },
    });
};

const logoutUser = (url: string) => {
    return axios.request({
        url: url,
        withCredentials: true,
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        method: "POST",
    });
};

export const useLogout = () => {
    const queryClient = useQueryClient();
    const url = `${BACKEND_URL}/api/logout`;

    return useMutation({
        mutationKey: [url],
        mutationFn: async () => {
            const response = await logoutUser(url);
            return response.data;
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [`${BACKEND_URL}/api/v1/session`] });
        },
    });
};

export const useGetSession = () => {
    return useQuery<Session>({
        queryKey: ["session"],
        queryFn: async () => {
            return (await getSession()).data;
        },
        refetchInterval: 30000 * 60,
        retry: 1,
    });
};

export const useRequestVerificationLink = () => {
    const url = `${BACKEND_URL}/api/email/verification-notification`;

    return useMutation({
        mutationKey: [url],
        mutationFn: async () => {
            return await axios.request({
                url: url,
                withCredentials: true,
                method: "POST",
            });
        },
    });
};

const editUser = (url: string, data: UserInfo) => {
    return axios.request({
        url: url,
        data: data,
        withCredentials: true,
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        method: "PUT",
    });
};

export const useEditUser = () => {
    const url = `${BACKEND_URL}/api/user/profile-information`;
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [url],
        mutationFn: async (data: UserInfo) => {
            return await editUser(url, data);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["session"], refetchType: "all" });
        },
    });
};

const changePassword = (url: string, data: PasswordChange) => {
    return axios.request({
        url: url,
        data: data,
        withCredentials: true,
        headers: { "Content-Type": "application/json", Accept: "application/json" },
        method: "PUT",
    });
};

export const useChangePassword = () => {
    const url = `${BACKEND_URL}/api/user/password`;
    return useMutation({
        mutationKey: [url],
        mutationFn: async (data: PasswordChange) => {
            return await changePassword(url, data);
        },
    });
};

const getResetPasswordLink = (url: string, email: string) => {
    return axios.request({
        url: url,
        data: { email: email },
        withCredentials: true,
        method: "POST",
    });
};

export const useGetResetPasswordLink = () => {
    const url = `${BACKEND_URL}/api/forgot-password`;
    return useMutation({
        mutationKey: [url],
        mutationFn: async (email: string) => {
            return await getResetPasswordLink(url, email);
        },
    });
};

const resetPassword = (url: string, data: ResetFormType) => {
    return axios.request({
        url: url,
        data: data,
        withCredentials: true,
        method: "POST",
    });
};

export const useResetPassword = () => {
    const url = `${BACKEND_URL}/api/reset-password`;
    return useMutation({
        mutationKey: [url],
        mutationFn: async (data: ResetFormType) => {
            return await resetPassword(url, data);
        },
    });
};
